import React, { Component } from "react";
import {
  Typography,
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import Form from "../components/common/Form";
import Onboarding from "../components/accounts/Onboarding";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import SubmitButton from "../components/accounts/SubmitButton";
import { navigate } from "gatsby";

import {
  OnboardingCreateApp,
  OnboardingCreateAppVariables
} from "../graphql/OnboardingCreateApp";
import { Field } from "react-final-form";
import Input from "../components/common/TextField";
import { compose, maxLength, required } from "../utils/validators";
import PrivateRoute from "../components/auth/PrivateRoute";

const styles = (theme: Theme) =>
  createStyles({
    details: {
      marginTop: theme.spacing(4),
      color: theme.palette.grey[700]
    }
  });

const mutation = gql`
  mutation OnboardingCreateApp($name: String!, $type: String!) {
    createApp(name: $name, type: $type) {
      id
      clientId
      clientSecret
    }
  }
`;

export class GetStarted extends Component<WithStyles<typeof styles>> {
  onComplete = () => {
    navigate("/first-app");
  };

  render() {
    const { classes } = this.props;

    return (
      <Onboarding
        title="Let's get started!"
        subtitle="To integrate with Certane, we will need a name to get your account created and provide test API keys."
      >
        <Mutation<OnboardingCreateApp, OnboardingCreateAppVariables>
          mutation={mutation}
          onCompleted={this.onComplete}
          awaitRefetchQueries
        >
          {createApp => (
            <Form
              onSubmit={(data: any) =>
                createApp({
                  variables: { name: data.appName, type: "Backend" }
                })
              }
            >
              {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    autoFocus
                    name="appName"
                    component={Input}
                    label="App Name"
                    placeholder="Awesome App"
                    fullWidth
                    validate={compose(
                      required("App name is required"),
                      maxLength(40, "App name can be at most 40 characters")
                    )}
                  />
                  <Typography className={classes.details}>
                    Not a developer? Don't worry, click to continue
                  </Typography>

                  <SubmitButton disabled={submitting} />
                </form>
              )}
            </Form>
          )}
        </Mutation>
      </Onboarding>
    );
  }
}

const GetStartedPage = withStyles(styles)(GetStarted);
export default () => <PrivateRoute component={GetStartedPage} />;
